// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VBurgerButton_lLhDi{display:none;height:100%;margin-left:auto;position:relative}@media(max-width:1279.98px){.VBurgerButton_lLhDi{align-items:center;display:flex;flex:unset}}@media(max-width:767.98px){.VBurgerButton_lLhDi{display:none}.VBurgerButton_lLhDi._opened_\\+rmxU{display:flex}}.button_FnV4x{height:5.6rem;width:5.6rem}@media(max-width:767.98px){.button_FnV4x{border-radius:1rem;height:4rem;width:4rem}}.button_FnV4x._opened_\\+rmxU{background-color:#855aff;color:#fff}@media(max-width:767.98px){.button_FnV4x._opened_\\+rmxU{background-color:#f1f1f4;color:#17181c}.button_FnV4x._opened_\\+rmxU .burger_gLt0l{height:1rem;width:1rem}}.button_FnV4x._opened_\\+rmxU .burger_gLt0l span:first-child{transform:translateY(-50%) rotate(45deg)}.button_FnV4x._opened_\\+rmxU .burger_gLt0l span:last-child{transform:translateY(-50%) rotate(-45deg)}.burger_gLt0l{color:inherit;height:1rem;margin-right:1.2rem;position:relative;width:1.6rem}@media(max-width:1279.98px){.burger_gLt0l{margin-right:0}}.burger_gLt0l span{background-color:currentcolor;border-top:1px solid;color:inherit;content:\"\";display:block;height:1.67px;left:0;position:absolute;top:50%;transition:transform .3s ease;width:100%}.burger_gLt0l span:first-child{transform:translateY(calc(-50% + .4rem))}.burger_gLt0l span:last-child{transform:translateY(calc(-50% - .4rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"VBurgerButton": "VBurgerButton_lLhDi",
	"_opened": "_opened_+rmxU",
	"button": "button_FnV4x",
	"burger": "burger_gLt0l"
};
module.exports = ___CSS_LOADER_EXPORT___;
