
export default {
    name: 'VBurgerButton',

    props: {
        /**
         * Определяет состояние открытого модального окна
         */
        isOpened: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return {
                [this.$style._opened]: this.isOpened,
            };
        },
    },

    methods: {
        onClickHandler() {
            this.$emit('menu-modal-toggle');
        },
    },
};
